import React from 'react'
import './style.scss'

const AppFooter = () => {
  return (
    <div className="footer">
      <div className="row">
        <div className="col-sm-12">
          <div className="footer__copyright">
            <span>
              © 2025{' '}
              <a href="http://www.google.com/" target="_blank" rel="noopener noreferrer">
                BRT Appeals
              </a>{' '}
              All rights reserved, {' '} {process.env.REACT_APP_VERSION}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AppFooter
