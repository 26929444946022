import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios, {AxiosResponse} from "axios";
import {selectUserCounty} from "./app";
import {
  County, CountyCommissioner,
  CountyConfiguration,
  CountyConfigurationResponse,
  LoadStatus,
  SaveStatus,
  SeasonConfiguration,
  TaxAdministrator,
  TaxBoard
} from "../commontypes/types";
import {RootState} from "./index";


type AdminState = {
  taxAdministrator: TaxAdministrator,
  taxBoard: TaxBoard,
  countyCommissioners: CountyCommissioner[],
  meetingRooms: string[],
  valueClassSeason: SeasonConfiguration,
  addOmitSeason: SeasonConfiguration,
  loadStatus: LoadStatus,
  loadError: string,
  saveStatus: SaveStatus,
  saveError: string,
}
const initialState:AdminState = {
  taxAdministrator: {
    name: "",
    title: ""
  },
  taxBoard: {
    name: "",
    address: []
  },
  countyCommissioners: [],
  meetingRooms: [],
  addOmitSeason: {
    season: {},
    municipalityOverrides: []
  },
  valueClassSeason: {
    season: {},
    municipalityOverrides: []
  },
  loadStatus: LoadStatus.NOT_LOADED,
  loadError: "",
  saveStatus: SaveStatus.IDLE,
  saveError: "",
}

export const fetchCountyConfiguration = createAsyncThunk<
  CountyConfiguration,
  string,
  { state:RootState }
>(
  'admin/fetchCountyConfiguration',
  async (countyId, thunkAPI) => {
    const state = thunkAPI.getState();
    const userCounty:County = selectUserCounty(state);
    const path = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/v2/${countyId || userCounty.key}/configuration`;
    const response:AxiosResponse<CountyConfigurationResponse> = await axios.get(path);
    return response.data.countyConfiguration;
  }
)

export const saveCountyConfiguration = createAsyncThunk<
  CountyConfiguration,
  CountyConfiguration,
  { state:RootState }
>(
  'admin/saveCountyConfiguration',
  async (countyConfiguration, {getState}) => {
    const state = getState();
    const userCounty:County = selectUserCounty(state);
    const {countyTaxAdministration, valueClassSeason, addOmitSeason} = countyConfiguration;
    const body = {
      countyTaxAdministration,
      addOmitSeason: addOmitSeason ? addOmitSeason : selectAddOmitSeason(state),
      valueClassSeason: valueClassSeason ? valueClassSeason : selectValueClassSeason(state),
    }
    const path = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_STAGE}/v2/${userCounty.key}/configuration`;
    const response:AxiosResponse<CountyConfiguration> = await axios.put(path, body);
    return response.data;
  }
)

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    clearState: () => initialState,
    clearCountyConfiguration: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCountyConfiguration.pending, (state) => {
      state.loadStatus = LoadStatus.IN_PROGRESS;
      state.loadError = "";
    })
      .addCase(fetchCountyConfiguration.rejected, (state, action) => {
        state.loadStatus = LoadStatus.FAILED;
        state.loadError = action.error.message!;
      })
      .addCase(fetchCountyConfiguration.fulfilled, (state, action) => {
        const config = action.payload;
        if (config) {
          state.taxAdministrator = config.countyTaxAdministration.taxAdministrator;
          state.taxBoard = config.countyTaxAdministration.taxBoard;
          state.meetingRooms = config.countyTaxAdministration.meetingRooms;
          state.countyCommissioners = config.countyTaxAdministration.countyCommissioners;
          state.addOmitSeason = config.addOmitSeason!;
          state.valueClassSeason = config.valueClassSeason!;
        }
        state.loadStatus = LoadStatus.SUCCESS;
      })
      .addCase(saveCountyConfiguration.pending, (state) => {
        state.saveStatus = SaveStatus.IN_PROGRESS;
        state.saveError = "";
      })
      .addCase(saveCountyConfiguration.rejected, (state, action) => {
        state.saveStatus = SaveStatus.FAILED;
        state.saveError = action.error.message!;
      })
      .addCase(saveCountyConfiguration.fulfilled, (state, action) => {
        const config = action.payload;
        state.taxAdministrator = config.countyTaxAdministration.taxAdministrator;
        state.taxBoard = config.countyTaxAdministration.taxBoard;
        state.countyCommissioners = config.countyTaxAdministration.countyCommissioners;
        state.meetingRooms = config.countyTaxAdministration.meetingRooms;
        state.addOmitSeason = config.addOmitSeason!;
        state.valueClassSeason = config.valueClassSeason!;
        state.saveStatus = SaveStatus.SUCCESS;
      })
  }
});

export const selectTaxAdministrator = (state:RootState) => state.admin.taxAdministrator;
export const selectTaxBoard = (state:RootState) => state.admin.taxBoard;
export const selectMeetingRooms = (state:RootState) => state.admin.meetingRooms;
export const selectCountyCommissioners = (state:RootState) => state.admin.countyCommissioners
export const selectValueClassSeason = (state:RootState) => state.admin.valueClassSeason;
export const selectAddOmitSeason = (state:RootState) => state.admin.addOmitSeason;
export const selectCountyConfigLoadStatus = (state:RootState) => state.admin.loadStatus;
export const selectCountyConfigLoadError = (state:RootState) => state.admin.loadError;
export const selectCountyConfigSaveStatus = (state:RootState) => state.admin.saveStatus;
export const selectCountyConfigSaveError = (state:RootState) => state.admin.saveError;
export const {clearCountyConfiguration} = adminSlice.actions;
export default adminSlice.reducer;



