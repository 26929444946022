import React, { useEffect, useReducer, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import {FloatButton, Form, Layout as AntLayout, Modal, Typography} from 'antd';
import Footer from '../Footer';
import Greeting from "../Greeting";
import Menu from '../Menu';
import Content from '../Content';
import Loader from '../Loader';
import LayoutState from '../LayoutState';
import SettingsSider from '../SettingsSider';
import { enquireScreen, unenquireScreen } from 'enquire-js'
import { useContainerQuery } from 'react-container-query';
import classNames from 'classnames';
import {
  selectIsUserLoaded,
  setUser,
  clearUser,
  selectUserAATError,
  selectIsUserLoading, sendUsernameToEmail
} from "../../../ducks/app";
import {useDispatch, useSelector} from 'react-redux';
import {Hub, I18n} from 'aws-amplify/utils';
import {confirmResetPassword, resetPassword, signIn, signUp, confirmSignUp, resendSignUpCode} from 'aws-amplify/auth';
import {
  Authenticator,
  View,
  Button as AmplifyButton,
  translations,
  useTheme,
  useAuthenticator,
  Heading, Input, Flex
} from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import './style.scss'
import {clearAppeal} from "../../../ducks/newAppeal";
import {clearAppeals} from "../../../ducks/appealList";
import {clearCountyConfiguration} from "../../../ducks/admin";

const AntContent = AntLayout.Content;
const AntFooter = AntLayout.Footer;

const query = {
  'screen-xs': {
    maxWidth: 575,
  },
  'screen-sm': {
    minWidth: 576,
    maxWidth: 767,
  },
  'screen-md': {
    minWidth: 768,
    maxWidth: 991,
  },
  'screen-lg': {
    minWidth: 992,
    maxWidth: 1199,
  },
  'screen-xl': {
    minWidth: 1200,
    maxWidth: 1599,
  },
  'screen-xxl': {
    minWidth: 1600,
  },
}

const Layout = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isForgotUsername, setIsForgotUsername] = useState(false);
  const [forgottenUsernameEmailSent, setForgottenUsernameEmailSent] = useState(false);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const dispatch = useDispatch();
  const isUserLoaded = useSelector(selectIsUserLoaded);
  const isUserLoading = useSelector(selectIsUserLoading);
  const aatError = useSelector(selectUserAATError);
  console.log('isUserLoaded:', isUserLoaded);
  useEffect(() => {
    const enquireHandler = enquireScreen(mobile => {
        setIsMobile(mobile);
      });
    forceUpdate();
    Hub.listen('auth', (data) => {
      const event = data.payload.event;
      console.log('Hub event:', event);
      if (event === "signedOut") {
        dispatch(clearUser());
        dispatch(clearAppeal());
        dispatch(clearAppeals());
        dispatch(clearCountyConfiguration());
      } else if (event === "signedIn") {
        dispatch(setUser());
      }
    });
    return () => unenquireScreen(enquireHandler);
  }, []);

  useEffect(  () => {
    const setUserState = async () => {
      //await dispatch(setUser(getCurrentUserAndGroups()));
      await dispatch(setUser());
    }
    setUserState();
    //return () => {dispatch(clearUser())};
  }, []);

  const [params, containerRef] = useContainerQuery(query);

  const displayForgotUsername = () => {setIsForgotUsername(true);}

  const submitForgotUsername = ({reminderEmailAddress}) => {
    dispatch(sendUsernameToEmail({email:reminderEmailAddress}));
    setForgottenUsernameEmailSent(true);
  }

  const onCloseForgotUsername = () => {
    setForgottenUsernameEmailSent(false);
    setIsForgotUsername(false);
  }


  const services = {
    handleSignUp: async (formData) => {
      let { username, password, options } = formData;
      username = username.toUpperCase();
      const {email, phone_number} = options.userAttributes;
      if (!/^[A-Z0-9]+$/.test(username)) {
        throw new Error('Username must be letters and numerals only');
        //throw new Error('Username must be uppercase letters and numerals only');
      }
      return signUp({
        username,
        password,
        options: {
          userAttributes: {email: email.toLowerCase(), phone_number},
          autoSignIn: true,
        }
      });
    },
    handleConfirmSignUp: async (formData) => {
      const {username, confirmationCode, options} = formData;
      return confirmSignUp({username: username.toUpperCase(), confirmationCode, options});
    },
    handleResendSignUpCode: async (formData) => {
      const {username} = formData;
      return resendSignUpCode(username.toUpperCase());
    },
    handleSignIn: async (formData) => {
      const { username, password } = formData;
      return signIn({
        username: username.toUpperCase(),
        password,
      });
    },
    handleForgotPassword: async ({username}) => {
      return resetPassword({username: username.toUpperCase()});
    },
    handleForgotPasswordSubmit: async ({username, confirmationCode, newPassword}) => {
      return confirmResetPassword({username:username.toUpperCase(), confirmationCode, newPassword});
    },
    handleResetPassword: async ({username}) => {
      return resetPassword({username: username.toUpperCase()});
    },
    handleResetPasswordSubmit: async ({username, confirmationCode, newPassword}) => {
      return confirmResetPassword({username:username.toUpperCase(), confirmationCode, newPassword});
    }
  };
  const formFields = {
    signIn: {
      username: {
        placeholder: 'Username',
        labelHidden: true
      },
      password: {
        placeholder: 'Password',
        labelHidden: true
      }
    },
    signUp: {
      username: {
        placeholder: 'Username (use letters and numerals only)',
        labelHidden:true,
        order: 1
      },
      email: {
        placeholder: 'janedoe@email.com',
        labelHidden: true,
        order: 2,
        isRequired: true,
      },
      phone_number: {
        placeholder: '6095551212',
        labelHidden: true,
        order:3,
        dialCodeList: ['+1'],
        isRequired: true,
      },
      password: {
        placeholder: 'Password',
        labelHidden: true,
        order:4
      },
      confirm_password: {
        placeholder: 'Confirm Password',
        labelHidden: true,
        order:5
      }
    }
  };
  const components = {
    SignIn: {
      Header: () => {
        const {tokens} = useTheme();

        return (
          <Heading padding={tokens.space.large} level={4} fontWeight={'normal'}>
            Please log in
          </Heading>
        );
      },
      Footer: () => {
        const { toForgotPassword, toSignUp } = useAuthenticator();

        return (
          <>
            <View textAlign="center">
              <AmplifyButton
                fontWeight="normal"
                onClick={toSignUp}
                size="small"
                variation="link"
              >
                Create Account
              </AmplifyButton>
            </View>
            <View textAlign="center">
              <AmplifyButton
                fontWeight="normal"
                onClick={toForgotPassword}
                size="small"
                variation="link"
              >
                Forgot Password?
              </AmplifyButton>
            </View>
            <View textAlign="center">
              <AmplifyButton
                fontWeight="normal"
                onClick={displayForgotUsername}
                size="small"
                variation="link"
              >
                Forgot Username?
              </AmplifyButton>
            </View>
              <Modal
                title="Lookup username"
                open={isForgotUsername}
                onOk={onCloseForgotUsername}
                onCancel={onCloseForgotUsername}
                okButtonProps={{disabled: !forgottenUsernameEmailSent}}
              >
                <Form onFinish={submitForgotUsername} initialValues={{reminderEmailAddress:''}}>
                  <Form.Item name="reminderEmailAddress" validateTrigger="onBlur" rules={[
                    {type:'email', message: "Email must be in the form myname@example.com"},
                    {max:100}
                  ]}>
                    <Input
                      type="email" size="small"
                      placeholder="Registered email address"
                      enterKeyHint="search"
                    />
                  </Form.Item>
                  <AmplifyButton
                    type="submit"
                    variation="primary"
                    isFullWidth={true}
                  >
                    Send Username
                  </AmplifyButton>
                  {forgottenUsernameEmailSent &&
                  <Typography.Paragraph>An email has been sent with the username on file.</Typography.Paragraph>
                  }
                </Form>
              </Modal>
            <View padding='30px'>
              To file an online appeal please register first using our secure online system.
              For best performance, we recommend using an up to date modern browser such as
              Google Chrome, Apple Safari, or Mozilla Firefox.
              For additional instructions and help please login and click on the 'Help Guides' link.
            </View>
          </>
        );
      },
    },
    SignUp: {
      Header: () => {
        const {tokens} = useTheme();

        return (
          <Heading padding={tokens.space.large} level={4} fontWeight={'normal'}>
            Sign up
          </Heading>
        );
      },
      Footer: () => {
        const { toSignIn } = useAuthenticator();

        return (
          <>
            <View textAlign="center">
              <AmplifyButton
                fontWeight="normal"
                onClick={toSignIn}
                size="small"
                variation="link"
              >
                Back to Login
              </AmplifyButton>
            </View>
            <View textAlign="center">
              <AmplifyButton
                fontWeight="normal"
                size="small"
                variation="link"
              >
                <a href="https://www.youtube.com/watch?v=7DQ_0EI6sog&amp;feature=youtu.be" target="_blank" rel="noopener noreferrer" style={{color:'inherit'}}>Help Video</a>
              </AmplifyButton>
            </View>
            <View paddingLeft='30px' paddingRight='30px' fontSize='small'>
              Password must be at least eight characters, one upper case, one lower case, one number,
              and one special character.
            </View>
            <View padding='30px'>
              To file an online appeal please register first using our secure online system.
              For best performance, we recommend using an up to date modern browser such as
              Google Chrome, Apple Safari, or Mozilla Firefox.
              For additional instructions and help please login and click on the 'Help Guides' link.
            </View>
          </>
        );
      },
    },
  };
  I18n.putVocabularies(translations);
  I18n.putVocabulariesForLanguage('en', {
    'Sign in': 'Log in'
  })

  return (
    <Authenticator formFields={formFields} components={components} services={services} >
      {({ signOut, user }) => (
    <div ref = {containerRef} className={classNames(params)}>
      <Greeting user={user} signOut={signOut} isMobile={isMobile} />
      <AntLayout style={{minHeight:"calc(100vh - 60px"}}>
        <LayoutState />
        <Loader />

        <FloatButton.BackTop />
        <Menu isMobile={isMobile} />
        <SettingsSider />
        <AntLayout style={{minHeight:"calc(100vh - 60px"}}>
          <AntContent>
            {isUserLoaded ? <Content signOut={signOut} user={user} /> : <div style={{ marginTop:"4.5em", marginLeft:"2.5em"}}>
              <h3>Loading...<ClipLoader size={20} loading={!isUserLoaded} /></h3>
              {aatError && <Typography.Text type="danger">${aatError}</Typography.Text>}
            </div>}
          </AntContent>
          <AntFooter>
            <Footer />
          </AntFooter>
        </AntLayout>
      </AntLayout>
    </div>
        )}
    </Authenticator>
  );
}

export default Layout;
// export default withAuthenticator(Layout, true, [
//     <AppSignIn/>,
//     <ConfirmSignIn/>,
//     <VerifyContact/>,
//     <AppSignUp/>,
//     <AppConfirmSignUp/>,
//     <AppForgotPassword/>
// ]);
